import { render, staticRenderFns } from "./POSInvoice_Aziz_Sir.vue?vue&type=template&id=b8cfa1ec&scoped=true&"
import script from "./POSInvoice_Aziz_Sir.vue?vue&type=script&lang=js&"
export * from "./POSInvoice_Aziz_Sir.vue?vue&type=script&lang=js&"
import style0 from "./POSInvoice_Aziz_Sir.vue?vue&type=style&index=0&id=b8cfa1ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8cfa1ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VIcon,VImg,VRow,VSimpleTable,VSpacer})
